export default {

  // if false, this prevents the popup from showing (useful for giving to control to another piece of code)
  enabled: true,

  // optional (expecting a HTML element) if passed, the popup is appended to this element. default is `document.body`
  container: null,

  // defaults cookie options - it is RECOMMENDED to set these values to correspond with your server
  cookie: {
    // This is the name of this cookie - you can ignore this
    name: 'cookieconsent',

    // This is the url path that the cookie 'name' belongs to. The cookie can only be read at this location
    path: '/',

    // This is the domain that the cookie 'name' belongs to. The cookie can only be read on this domain.
    //  - Guide to cookie domains - http://erik.io/blog/2014/03/04/definitive-guide-to-cookie-domains/
    domain: '',

    // The cookies expire date, specified in days (specify -1 for no expiry)
    expiryDays: 365,
  },

  // these callback hooks are called at certain points in the program execution
  onPopupOpen: function() {},
  onPopupClose: function() {},
  onInitialise: function(status) {},
  onStatusChange: function(status, chosenBefore) {},
  onRevokeChoice: function() {},

  // each item defines the inner text for the element that it references
  content: {
    header: 'Cookies used on the website!',
    message: 'Redbooth uses cookies to ensure your best experience.',
    dismiss: 'Got it!',
    allow: 'Got It',
    deny: 'Decline',
    link: 'Learn More',
    href: 'https://redbooth.com/more-info/terms-and-fair-use',
    close: '&#x274c;',
    settings: 'Cookie Settings',
    confirm: 'Save & Accept',
    necessary: 'Allow necessary cookies for the Redbooth webapp',
    marketing: 'Allow cookies for marketing pages',
  },

  // This is the HTML for the elements above. The string {{header}} will be replaced with the equivalent text below.
  // You can remove "{{header}}" and write the content directly inside the HTML if you want.
  //
  //  - ARIA rules suggest to ensure controls are tabbable (so the browser can find the first control),
  //    and to set the focus to the first interactive control (http://w3c.github.io/aria-in-html/)
  elements: {
    header: '<span class="cc-header">{{header}}</span>&nbsp;',
    message: '<span id="cookieconsent:desc" class="cc-message cc-message-main">{{message}}</span>',
    messagelink: '<span id="cookieconsent:desc" class="cc-message">{{message}} <a aria-label="learn more about cookies" role=button tabindex="0" class="cc-link" href="{{href}}" rel="noopener noreferrer nofollow" target="_blank">{{link}}</a></span>',
    dismiss: '<a aria-label="dismiss cookie message" role=button tabindex="0" class="cc-btn cc-dismiss">{{dismiss}}</a>',
    allow: '<a aria-label="allow cookies" role=button tabindex="0"  class="cc-btn cc-allow">{{allow}}</a>',
    deny: '<a aria-label="deny cookies" role=button tabindex="0" class="cc-btn cc-deny">{{deny}}</a>',
    link: '<a aria-label="learn more about cookies" role=button tabindex="0" class="cc-link" href="{{href}}" target="_blank">{{link}}</a>',
    close: '<span aria-label="dismiss cookie message" role=button tabindex="0" class="cc-close">{{close}}</span>',
    settings: '<a aria-label="cookie settings" role=button tabindex="0" class="cc-link cc-settings">{{settings}}</a>',
    necessaryCheckbox: '<input id="selectedNecessary" class="disabled" name="necessary" type="checkbox" value="necessary" checked disabled />',
    marketingCheckbox: '<input aria-label="select to accept marking cookies" id="selectMarketing" class="cc-check-accept" name="unnecessary" type="checkbox" value="unnecessary" checked/>',
    necessary: '<label for="selectedNecessary" class="disabled">{{necessary}}</label>',
    marketing: '<label for="selectMarketing">{{marketing}}</label>',
    confirm: '<a aria-label="save settings" role=button tabindex="0"  class="cc-btn cc-confirm">{{confirm}}</a>'
    //compliance: compliance is also an element, but it is generated by the application, depending on `type` below
  },

  // The placeholders {{classes}} and {{children}} both get replaced during initialisation:
  //  - {{classes}} is where additional classes get added
  //  - {{children}} is where the HTML children are placed
  window: '<div role="dialog" aria-live="polite" aria-label="cookieconsent" aria-describedby="cookieconsent:desc" class="cc-window {{classes}}"><!--googleoff: all-->{{children}}<!--googleon: all--></div>',

  // This is the html for the revoke button. This only shows up after the user has selected their level of consent
  // It can be enabled or disabled using the `revokable` option
  revokeBtn: '<div class="cc-revoke {{classes}}">Cookie Policy</div>',

  // define types of 'compliance' here. '{{value}}' strings in here are linked to `elements`
  compliance: {
    'info': '<div class="cc-compliance">{{dismiss}}</div>',
    'opt-in': '<div class="cc-compliance">{{allow}}</div>',
    'opt-out': '<div class="cc-compliance cc-highlight">{{deny}}{{dismiss}}</div>',
  },

  // select your type of popup here
  type: 'opt-in', // refers to `compliance` (in other words, the buttons that are displayed)

  // define layout layouts here
  layouts: {
    // the 'block' layout tend to be for square floating popups
    'basic': '{{message}}{{settings}}{{compliance}}',
    'basic-settings': '<div class="cc-message">{{necessaryCheckbox}}{{necessary}}<br/>{{marketingCheckbox}}{{marketing}}</div>{{link}}{{confirm}}',
    // add a custom layout here, then add some new css with the class '.cc-layout-my-cool-layout'
    //'my-cool-layout': '<div class="my-special-layout">{{message}}{{compliance}}</div>{{close}}',
  },

  // default layout (see above)
  layout: 'basic',

  // this refers to the popup windows position. we currently support:
  //  - banner positions: top, bottom
  //  - floating positions: top-left, top-right, bottom-left, bottom-right
  //
  // adds a class `cc-floating` or `cc-banner` which helps when styling
  position: 'bottom', // default position is 'bottom'

  // Available styles
  //    -block (default, no extra classes)
  //    -edgeless
  //    -classic
  // use your own style name and use `.cc-theme-STYLENAME` class in CSS to edit.
  // Note: style "wire" is used for the configurator, but has no CSS styles of its own, only palette is used.
  theme: 'classic',

  // The popup is `fixed` by default, but if you want it to be static (inline with the page content), set this to false
  // Note: by default, we animate the height of the popup from 0 to full size
  static: false,

  // if you want custom colours, pass them in here. this object should look like this.
  // ideally, any custom colours/themes should be created in a separate style sheet, as this is more efficient.
  //   {
  //     popup: {background: '#000000', text: '#fff', link: '#fff'},
  //     button: {background: 'transparent', border: '#f8e71c', text: '#f8e71c'},
  //     highlight: {background: '#f8e71c', border: '#f8e71c', text: '#000000'},
  //   }
  // `highlight` is optional and extends `button`. if it exists, it will apply to the first button
  // only background needs to be defined for every element. if not set, other colors can be calculated from it
  palette: null,

  // Some countries REQUIRE that a user can change their mind. You can configure this yourself.
  // Most of the time this should be false, but the `cookieconsent.law` can change this to `true` if it detects that it should
  revokable: false,

  // if true, the revokable button will tranlate in and out
  animateRevokable: true,

  // used to disable link on existing layouts
  // replaces element messagelink with message and removes content of link
  showLink: true,

  // set value as scroll range to enable
  dismissOnScroll: false,

  // set value as time in milliseconds to autodismiss after set time
  dismissOnTimeout: false,

  // The application automatically decide whether the popup should open.
  // Set this to false to prevent this from happening and to allow you to control the behaviour yourself
  autoOpen: true,

  // By default the created HTML is automatically appended to the container (which defaults to <body>). You can prevent this behaviour
  // by setting this to false, but if you do, you must attach the `element` yourself, which is a public property of the popup instance:
  //
  //     var instance = cookieconsent.factory(options);
  //     document.body.appendChild(instance.element);
  //
  autoAttach: true,

  // simple whitelist/blacklist for pages. specify page by:
  //   - using a string : '/index.html'           (matches '/index.html' exactly) OR
  //   - using RegExp   : /\/page_[\d]+\.html/    (matched '/page_1.html' and '/page_2.html' etc)
  whitelistPage: [],
  blacklistPage: [],

  // If this is defined, then it is used as the inner html instead of layout. This allows for ultimate customisation.
  // Be sure to use the classes `cc-btn` and `cc-allow`, `cc-deny` or `cc-dismiss`. They enable the app to register click
  // handlers. You can use other pre-existing classes too. See `src/styles` folder.
  overrideHTML: null,
};
