import util from '../lib/utils';
import gtm from '../lib/gtm';
import popup from '../lib/popup';
import defaultOptions from '../config/cookie-popup';

class CookiePopup {

  constructor(options) {
    this.initialise(options);
    document.addEventListener('retrigger', this.retrigger.bind(this));
  }

  static get cookieStatus() {
    return {
      deny: 'deny',
      allow: 'allow',
      dismiss: 'dismiss'
    };
  }

  static get allowedStatuses() {
    return Object.keys(CookiePopup.cookieStatus).map(util.escapeRegExp);
  }

  static get transitionEnd() {
    return util.getTransitionEnd();
  }

  static get hasTransition() {
    return !!CookiePopup.transitionEnd;
  }

  initialise(options) {
    if (this.options) {
      this.destroy(); // already rendered
    }

    // set options back to default options
    util.deepExtend(this.options = {}, defaultOptions);

    // merge in user options
    if (util.isPlainObject(options)) {
      util.deepExtend(this.options, options);
    }

    // returns true if `onComplete` was called
    if (this.checkCallbackHooks()) {
      // user has already answered
      this.options.enabled = false;
    }

    // apply blacklist / whitelist
    if (util.arrayContainsMatches(this.options.blacklistPage, location.pathname)) {
      this.options.enabled = false;
    }
    if (util.arrayContainsMatches(this.options.whitelistPage, location.pathname)) {
      this.options.enabled = true;
    }

    // the full markup either contains the wrapper or it does not (for multiple instances)
    this.buildPopup();
  }

  createSettings() {
    const parent = this.element.parentElement;
    parent.removeChild(this.element);
    this.element = null;

    this.buildPopup({layout: 'basic-settings'}, false);
    this.applySelectedStatus();

    return this;
  }

  buildPopup(extras = {}, invisible=true) {
    const innerMarkupOptions = Object.assign(this.options, extras);
    const cookiePopup = this.options.window
      .replace('{{classes}}', popup.getPopupClasses(this.options).join(' '))
      .replace('{{children}}', popup.getPopupInnerMarkup(innerMarkupOptions));

      // if static, we need to grow the element from 0 height so it doesn't jump the page
      // content. we wrap an element around it which will mask the hidden content
      if (this.options.static) {
        // `grower` is a wrapper div with a hidden overflow whose height is animated
        const wrapper = this.appendMarkup('<div class="cc-grower">' + cookiePopup + '</div>');

        wrapper.style.display = ''; // set it to visible (because appendMarkup hides it)
        this.element = wrapper.firstChild; // get the `element` reference from the wrapper

        if (invisible) {
          this.element.style.display = 'none';
          util.addClass(this.element, 'cc-invisible');
        }
      } else {
        this.element = this.appendMarkup(cookiePopup);
      }
    return this;
  }

  retrigger() {
    if (this.element) {
      this.open();
    } else {
      this.initialise();
    }
  }

  destroy() {
    document.removeEventListener('retrigger');

    if (this.onButtonClick && this.element) {
      this.element.removeEventListener('click', this.onButtonClick);
      this.onButtonClick = null;
    }

    if (this.dismissTimeout) {
      clearTimeout(this.dismissTimeout);
      this.dismissTimeout = null;
    }

    if (this.onWindowScroll) {
      window.removeEventListener('scroll', this.onWindowScroll);
      this.onWindowScroll = null;
    }

    if (this.onMouseMove) {
      window.removeEventListener('mousemove', this.onMouseMove);
      this.onMouseMove = null;
    }

    if (this.element && this.element.parentNode) {
      this.element.parentNode.removeChild(this.element);
    }
    this.element = null;

    if (this.revokeBtn && this.revokeBtn.parentNode) {
      this.revokeBtn.parentNode.removeChild(this.revokeBtn);
    }
    this.revokeBtn = null;

    popup.removeCustomStyle(this.options.palette);
    this.options = null;
  }

  open() {
    if (!this.element) return;

    if (!this.isOpen()) {
      if (CookiePopup.hasTransition) {
        this.fadeIn();
      } else {
        this.element.style.display = '';
      }

      this.options.onPopupOpen.call(this);
    }

    return this;
  }

  isOpen() {
    return this.element &&
      this.element.style.display == '' &&
      (CookiePopup.hasTransition ? !util.hasClass(this.element, 'cc-invisible') : true);
  }

  close() {
    if (!this.element) return;

    if (this.isOpen()) {
      if (CookiePopup.hasTransition) {
        this.fadeOut();
      } else {
        this.element.style.display = 'none';
      }
      this.options.onPopupClose.call(this);
    }

    return this;
  }

  appendMarkup(markup) {
    const opts = this.options;
    const div = document.createElement('div');
    const cont = (opts.container && opts.container.nodeType === 1) ? opts.container : document.body;

    div.innerHTML = markup;

    const el = div.children[0];

    el.style.display = 'none';

    if (util.hasClass(el, 'cc-window') && CookiePopup.hasTransition) {
      util.addClass(el, 'cc-invisible');
    }

    // save ref to the function handle so we can unbind it later
    this.onButtonClick = this.handleButtonClick.bind(this);

    el.addEventListener('click', this.onButtonClick);

    if (opts.autoAttach) {
      if (!cont.firstChild) {
        cont.appendChild(el);
      } else {
        cont.insertBefore(el, cont.firstChild)
      }
    }

    return el;
  }

  handleButtonClick(event) {
    const targ = event.target;
    if (util.hasClass(targ, 'cc-btn')) {

      const matches = targ.className.match(new RegExp("\\bcc-(" + CookiePopup.allowedStatuses.join('|') + ")\\b"));
      const match = (matches && matches[1]) || false;

      if (match) {
        this.setStatus(match);
        this.close(true);
      }
    }
    if (util.hasClass(targ, 'cc-settings')) {
      this.createSettings().open();
    }
    if (util.hasClass(targ, 'cc-check-accept')) {
      this.setStatus(targ.checked ? CookiePopup.cookieStatus.allow : CookiePopup.cookieStatus.deny);
    }
    if (util.hasClass(targ, 'cc-confirm')) {
      this.setStatus(this.getSelectedStatus());
      this.close(true);
    }
    if (util.hasClass(targ, 'cc-close')) {
      this.setStatus(CookiePopup.cookieStatus.dismiss);
      this.close(true);
    }
    if (util.hasClass(targ, 'cc-revoke')) {
      this.revokeChoice();
    }
  }

  // returns true if the cookie has a valid value
  hasAnswered() {
    return Object.keys(CookiePopup.cookieStatus).indexOf(this.getStatus()) >= 0;
  }

  getSelectedStatus() {
    const currentStatus = this.getStatus();
    if (currentStatus) return currentStatus;

    const marketingCheckbox = document.getElementById('selectMarketing');
    return (!marketingCheckbox || marketingCheckbox.checked) ? CookiePopup.cookieStatus.allow : CookiePopup.cookieStatus.deny;
  }

  applySelectedStatus() {
    const marketingCheckbox = document.getElementById('selectMarketing');
    if (marketingCheckbox) {
      marketingCheckbox.checked = this.getSelectedStatus() !== CookiePopup.cookieStatus.deny;
    }
  }

  setStatus(status) {
    const cookieStatus = CookiePopup.cookieStatus;
    const c = this.options.cookie;
    const oldStatus = this.getStatus();
    const chosenBefore = Object.keys(cookieStatus).indexOf(oldStatus) >= 0;
    const alreadyAllowed = oldStatus === cookieStatus.allow;

    // if `status` is valid
    if (Object.keys(cookieStatus).indexOf(status) >= 0) {
      util.setCookie(c.name, status, c.expiryDays, c.domain, c.path);

      // We will need to resend the event to GTM to trigger tags
      status === cookieStatus.allow && !alreadyAllowed && gtm.enableCookies();
      this.options.onStatusChange.call(this, status, chosenBefore);
    } else {
      this.clearStatus();
      cookies.disable();
    }
  }

  // returns saved cookie value
  getStatus() {
    return util.getCookie(this.options.cookie.name);
  }

  clearStatus() {
    const c = this.options.cookie;
    util.setCookie(c.name, '', -1, c.domain, c.path);
  }

  fadeIn() {
    const el = this.element;

    if (!CookiePopup.hasTransition || !el) return;

    // This should always be called AFTER fadeOut (which is governed by the 'transitionend' event).
    // 'transitionend' isn't all that reliable, so, if we try and fadeIn before 'transitionend' has
    // has a chance to run, then we run it ourselves
    if (this.afterTransition) {
      this.afterFadeOut(el)
    }

    if (util.hasClass(el, 'cc-invisible')) {
      el.style.display = '';

      if (this.options.static) {
        const height = this.element.clientHeight;
        this.element.parentNode.style.maxHeight = height + 'px';
      }

      const fadeInTimeout = 20; // (ms) DO NOT MAKE THIS VALUE SMALLER. See below

      // Although most browsers can handle values less than 20ms, it should remain above this value.
      // This is because we are waiting for a "browser redraw" before we remove the 'cc-invisible' class.
      // If the class is remvoed before a redraw could happen, then the fadeIn effect WILL NOT work, and
      // the popup will appear from nothing. Therefore we MUST allow enough time for the browser to do
      // its thing. The actually difference between using 0 and 20 in a set timeout is neglegible anyway
      this.openingTimeout = setTimeout(this.afterFadeIn.bind(this, el), fadeInTimeout);
    }
  }

  fadeOut() {
    const el = this.element;

    if (!CookiePopup.hasTransition || !el) return;

    if (this.openingTimeout) {
      clearTimeout(this.openingTimeout);
    }

    if (!util.hasClass(el, 'cc-invisible')) {
      if (this.options.static) {
        this.element.parentNode.style.maxHeight = '';
      }

      this.afterTransition = this.afterFadeOut.bind(this, el);
      el.addEventListener(CookiePopup.transitionEnd, this.afterTransition);

      util.addClass(el, 'cc-invisible');
    }
  }

  afterFadeIn(el) {
    // This needs to be called after 'fadeIn'. This is the code that actually causes the fadeIn to work
    // There is a good reason why it's called in a timeout. Read 'fadeIn';
    this.openingTimeout = null;
    util.removeClass(el, 'cc-invisible');
  }

  afterFadeOut(el) {
    // This is called on 'transitionend' (only on the transition of the fadeOut). That's because after we've faded out, we need to
    // set the display to 'none' (so there aren't annoying invisible popups all over the page). If for whenever reason this function
    // is not called (lack of support), the open/close mechanism will still work.
    el.style.display = 'none'; // after close and before open, the display should be none
    el.removeEventListener(CookiePopup.transitionEnd, this.afterTransition);
    this.afterTransition = null;
  }

  checkCallbackHooks() {
    const complete = this.options.onInitialise.bind(this);

    if (!window.navigator.cookieEnabled) {
      complete(CookiePopup.cookieStatus.deny);
      return true;
    }

    if (window.CookiesOK || window.navigator.CookiesOK) {
      complete(CookiPopup.cookieStatus.allow);
      return true;
    }

    const allowed = Object.keys(CookiePopup.cookieStatus);
    const answer = this.getStatus();
    const match = allowed.indexOf(answer) >= 0;

    match && complete(answer);
    return match;
  }
}

export default CookiePopup;
