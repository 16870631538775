import util from '../lib/utils';

/*
First we need to get the script that hosts the Maxmind geoip2 Javascript API.
We fetch this and insert the script into the current page. This should give us
access to the API, set on the window object. Once that is loaded, we execute a call
to the Maxmind country endpoint to fetch the country information.
*/

class Location {

  constructor(options) {
    this.options = {
      timeout: 5000,
      url: '//js.maxmind.com/js/apis/geoip2/v2.1/geoip2.js',
    };

    if (util.isPlainObject(options)) {
      util.deepExtend(this.options, options);
    }
  }

  runService(done) {
    // If everything went okay then `geoip2` should be defined
    if (!window.geoip2) {
      return done(new Error('Cannot find `geoip2` on the global scope'));
    }

    // node-style callbacks <3
    window.geoip2.country(location => {
      try {
        done(null, { code: location.country.iso_code });
      } catch (err) {
        done(err);
      }
    }, done);
  }

  // Runs the geolocate service
  // We embed the script into the page here,
  // That gives us access to window.geoip2
  locate(done) {
    if (!this.options.url) return done(new Error('No services to run'));

    util.getScript(
      this.options.url,
      this.options.timeout,
      this.runService.bind(this, done),
    );
  }
}

export default Location;
