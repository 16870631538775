import util from './utils';

// contains references to the custom <style> tags
const customStyles = {};

function getPositionClasses(opts) {
  const positions = opts.position.split('-'); // top, bottom, left, right
  return positions.map(cur => `cc-${cur}`);
}

function getPopupClasses(opts) {
  let positionStyle = (opts.position == 'top' || opts.position == 'bottom') ? 'banner' : 'floating';

  if (util.isMobile()) {
    positionStyle = 'floating';
  }

  const classes = ([
    'cc-' + positionStyle, // floating or banner
    'cc-type-' + opts.type, // add the compliance type
    'cc-theme-' + opts.theme, // add the theme
  ]).concat(getPositionClasses(opts));

  if (opts.static) {
    classes.push('cc-static');
  }

  // we only add extra styles if `palette` has been set to a valid value
  const customStyleSelector = attachCustomPalette(opts.palette);

  // if we override the palette, add the class that enables this
  customStyleSelector && classes.push(customStyleSelector);

  return classes;
}

function getPopupInnerMarkup(opts) {
  const interpolated = {};

  // removes link if showLink is false
  if (!opts.showLink) {
    opts.elements.link = '';
    opts.elements.messagelink = opts.elements.message;
  }

  Object.keys(opts.elements).forEach(function(prop) {
    interpolated[prop] = util.interpolateString(opts.elements[prop], function(name) {
      const str = opts.content[name];
      return (name && typeof str == 'string' && str.length) ? str : '';
    })
  });

  // checks if the type is valid and defaults to info if it's not
  const complianceType = opts.compliance[opts.type] || opts.compliance.info;

  // build the compliance types from the already interpolated `elements`
  interpolated.compliance = util.interpolateString(complianceType, function(name) {
    return interpolated[name];
  });

  // checks if the layout is valid and defaults to basic if it's not
  const layout = opts.layouts[opts.layout] || opts.layouts.basic;

  return util.interpolateString(layout, function(match) {
    return interpolated[match];
  });
}

function attachCustomPalette(palette) {
  const hash = util.hash(JSON.stringify(palette));
  const selector = 'cc-color-override-' + hash;
  const isValid = util.isPlainObject(palette);

  if (isValid) {
    addCustomStyle(hash, palette, '.' + selector);
    return selector;
  }
  return;
}

// TODO: Change this to inline styles for increased specificity
function addCustomStyle(hash, palette, prefix) {

  // only add this if a style like it doesn't exist
  if (customStyles[hash]) {
    // custom style already exists, so increment the reference count
    ++customStyles[hash].references;
    return;
  }

  const colorStyles = {};
  let popup = palette.popup;
  let button = palette.button;
  let highlight = palette.highlight;

  // needs background colour, text and link will be set to black/white if not specified
  if (popup) {
    // assumes popup.background is set
    popup.text = popup.text ? popup.text : util.getContrast(popup.background);
    popup.link = popup.link ? popup.link : popup.text;
    colorStyles[prefix + '.cc-window'] = [
      'color: ' + popup.text,
      'background-color: ' + popup.background
    ];
    colorStyles[prefix + '.cc-revoke'] = [
      'color: ' + popup.text,
      'background-color: ' + popup.background
    ];
    colorStyles[prefix + ' .cc-link,' + prefix + ' .cc-link:active,' + prefix + ' .cc-link:visited'] = [
      'color: ' + popup.link
    ];

    if (button) {
      // assumes button.background is set
      button.text = button.text ? button.text : util.getContrast(button.background);
      button.border = button.border ? button.border : 'transparent';
      colorStyles[prefix + ' .cc-btn'] = [
        'color: ' + button.text,
        'border-color: ' + button.border,
        'background-color: ' + button.background
      ];

      if(button.background != 'transparent')
        colorStyles[prefix + ' .cc-btn:hover, ' + prefix + ' .cc-btn:focus'] = [
          'background-color: ' + getHoverColour(button.background)
        ];

      if (highlight) {
        //assumes highlight.background is set
        highlight.text = highlight.text ? highlight.text : util.getContrast(highlight.background);
        highlight.border = highlight.border ? highlight.border : 'transparent';
        colorStyles[prefix + ' .cc-highlight .cc-btn:first-child'] = [
          'color: ' + highlight.text,
          'border-color: ' + highlight.border,
          'background-color: ' + highlight.background
        ];
      } else {
        // sets highlight text color to popup text. background and border are transparent by default.
        colorStyles[prefix + ' .cc-highlight .cc-btn:first-child'] = [
          'color: ' + popup.text
        ];
      }
    }
  }

  // this will be interpretted as CSS. the key is the selector, and each array element is a rule
  const style = document.createElement('style');
  document.head.appendChild(style);

  // custom style doesn't exist, so we create it
  customStyles[hash] = {
    references: 1,
    element: style.sheet
  };

  let ruleIndex = -1;
  for (let prop in colorStyles) {
    if (colorStyles.hasOwnProperty(prop)) {
      style.sheet.insertRule(prop + '{' + colorStyles[prop].join(';') + '}', ++ruleIndex);
    }
  }
}

function getHoverColour(hex) {
  hex = util.normaliseHex(hex);
  // for black buttons
  if (hex == '000000') {
    return '#222';
  }
  return util.getLuminance(hex);
}

function removeCustomStyle(palette) {
  if (util.isPlainObject(palette)) {
    const hash = util.hash(JSON.stringify(palette));
    const customStyle = customStyles[hash];
    if (customStyle && !--customStyle.references) {
      const styleNode = customStyle.element.ownerNode;
      if (styleNode && styleNode.parentNode) {
        styleNode.parentNode.removeChild(styleNode);
      }
      customStyles[hash] = null;
    }
  }
}

export default {
  getPopupClasses,
  getPopupInnerMarkup,
  removeCustomStyle,
};
