import util from '../lib/utils';
import defaultOptions from '../config/law';

function Law(options) {
  this.initialise.apply(this, arguments);
}

Law.prototype.initialise = function(options) {
  // set options back to default options
  util.deepExtend(this.options = {}, defaultOptions);

  // merge in user options
  if (util.isPlainObject(options)) {
    util.deepExtend(this.options, options);
  }
};

Law.prototype.hasLaw = function(countryCode) {
  return countryCode && this.options.hasLaw.indexOf(countryCode) >= 0;
};

export default Law;
