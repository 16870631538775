import util from '../lib/utils';
import CookiePopup from '../CookiePopup';
import Location from '../Location';
import Law from '../Law';

class CookieConsent {

  constructor(options) {
    this.options = options;
    this.hasTriggered = false;
  }

  triggerPopup() {
    const law = new Law(this.options.law);
    const popup = new CookiePopup(this.options);

    if (popup.hasAnswered()) return;

    // node-style callbacks <3
    this.getCountryCode((err, result) => {
      this.hasTriggered = true;
      if (err) {
        console.error(new Error('There was an error retrieving the country code: ', err));
        return popup.open();
      }
      law.hasLaw(result.code) ? popup.open() : popup.setStatus(CookiePopup.cookieStatus.allow);
    });
  }

  getCountryCode(done) {
    if (this.options.location) {
      var locator = new Location(this.options.location);
      return locator.locate(done);
    }
    done(null, {});
  }
}

export default new CookieConsent({
  location: true,
  cookie: {
    domain: util.getDomainName(window.location.hostname),
  },
});
