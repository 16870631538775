import cc from './CookieConsent';

window.addEventListener("load", function(){
  /*
  * Attach to the window so that just in case the script is included more than
  * once, we don't automatically trigger this more than once.
  */
  if (!window.cookieConsent) {
    window.cookieConsent = cc;
  }

  if (!window.cookieConsent.hasTriggered) {
    window.cookieConsent.triggerPopup();
  }

  const trigger = document.getElementsByClassName('cookieTrigger')[0];
  const triggerEvent = document.createEvent('Event');
  triggerEvent.initEvent('retrigger', true, true);

  if (trigger) {
    trigger.onclick = function(event) {
      event && event.preventDefault();
      triggerEvent && document.dispatchEvent(triggerEvent);
    };
  }
});
